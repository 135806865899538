import EstateKit from "./estatekit.svg";
import Loader from "./loader.svg";
import React from "react";
import "./index.css";

export default class LoadingScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasLoaded: false };
  }

  componentDidMount() {
    const WaitBeforeExit = 2500;
    document.body.classList.add("loading");
    setTimeout(() => {
      this.setState({ hasLoaded: true });
      document.body.classList.remove("loading");
    }, WaitBeforeExit);
  }

  render() {
    return !this.state.hasLoaded ? (
      <main className="flex flex-wrap items-center justify-center h-screen w-full z-[50] loading-screen">
        <div className="w-full mt-48 flex space-y-6 flex-wrap items-center justify-center">
          <div className="w-full spinning flex flex-wrap justify-center">
            <img src={Loader} alt="Loading..." />
          </div>

          <div className="w-full flex flex-wrap justify-center">
            <h2 className="w-full text-center text-white text-2xl tracking-wide">
              Working...
            </h2>

            <h4 className="w-full text-center text-gray-100 text-lg">
              (Please wait)
            </h4>
          </div>
        </div>

        <div className="mt-24">
          <img src={EstateKit} alt="EstateKit's Svg Logo" />
        </div>
      </main>
    ) : (
      <main></main>
    );
  }
}
