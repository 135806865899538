import React, { useState, useRef, useEffect } from "react";
import { IoChevronDown, IoCheckmarkCircle } from "react-icons/io5";
import Logo from "../assets/primary.svg";
import { Link } from "react-router-dom";
import HeroBg from "../assets/herobg.png";
import Crystal from "../assets/crystal ball.png";
import Bolt from "../assets/bolt.svg";
import config from "../../config";
import Button from "./Button";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const Hero = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [finalQuestion, setFinalQuestion] = useState(false);
  const [finalInput, setFinalInput] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false); // New loading state

  // Create a ref for the final input field
  const finalInputRef = useRef(null);

  useEffect(() => {
    // Focus on the final input when it becomes visible
    if (finalQuestion && finalInputRef.current) {
      finalInputRef.current.focus();
    }
  }, [finalQuestion]); // Run this effect when finalQuestion changes

  const scrollToNext = () => {
    const nextSection = document.getElementById("next-section");
    nextSection.scrollIntoView({ behavior: "smooth" });
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    if (!emailRegex.test(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
      setFinalQuestion(true);
    }
  };

  const handleFinalSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading state
    let deviceInfo = "Unknown";
    if (/Android/i.test(navigator.userAgent)) {
      deviceInfo = "Mobile - Android";
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      deviceInfo = "Mobile - iOS";
    } else if (/Windows/i.test(navigator.userAgent)) {
      deviceInfo = "Windows";
    } else if (/Mac/i.test(navigator.userAgent)) {
      deviceInfo = "Mac";
    }

    const fetchIpAndCountry = async () => {
      try {
        const [ipResponse, locationResponse] = await Promise.all([
          fetch("https://api64.ipify.org?format=json"),
          fetch(`${config.MailServer}/ip-data`),
        ]);

        const ipData = await ipResponse.json();
        const locationData = (await locationResponse.json()).data;

        return {
          ipAddress: ipData.ip || "Unknown",
          country: locationData.country_name || "Unknown",
          countryCode: locationData.country_code || "Unknown",
          city: locationData.city || "Unknown",
        };
      } catch (error) {
        console.error("Error fetching IP or location data:", error);
        return {
          ipAddress: "Unknown",
          country: "Unknown",
          countryCode: "Unknown",
          city: "Unknown",
        };
      }
    };

    const { ipAddress, country, countryCode, city } = await fetchIpAndCountry();

    const getFlagEmoji = (countryCode) => {
      return countryCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(127397 + char.charCodeAt())
        );
    };

    const countryFlag = getFlagEmoji(countryCode);
    const webhookURL =
      "https://discord.com/api/webhooks/1292143588360192052/tKlWMhP80MK0FE--WjOOYvtSw9XY7fZSMCn4GD1Gy4xHkv3WoLyIO02RLEg4evfT99H2";

    const payload = {
      embeds: [
        {
          title: "New Submission 📥",
          description: "A new form submission has been received.",
          color: 65280,
          fields: [
            {
              name: "Email 📧",
              value: email || "Not provided",
              inline: true,
            },
            {
              name: "Name 📝",
              value: finalInput || "Not provided",
              inline: true,
            },
            {
              name: "IP Address 🌐",
              value: ipAddress,
              inline: true,
            },
            {
              name: `Country ${countryFlag}`,
              value: `${country} (${countryCode})`,
              inline: true,
            },
            {
              name: "City 🏙️",
              value: city,
              inline: true,
            },
            {
              name: "Device Info 📱",
              value: deviceInfo,
              inline: true,
            },
          ],
          footer: {
            text: "Form Submission - Powered by EstateKit",
          },
          timestamp: new Date().toISOString(),
        },
      ],
    };

    // try {
    //   const response = await fetch(webhookURL, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(payload),
    //   });

    //   if (response.ok) {
    //     setSuccessMessage("Please check your inbox in about 10 Mins");
    //     setTimeout(() => {
    //       scrollToNext();
    //     }, 3000);
    //   } else {
    //     throw new Error("Failed to send data to Discord");
    //   }
    // } catch (error) {
    //   console.error("Error sending data to Discord:", error);
    //   alert("There was an error sending data to Discord.");
    // } finally {
    //   setLoading(false); // End loading state
    // }

    fetch(`${config.MailServer}/mail`, {
      method: "POST",
      body: JSON.stringify({
        receiver: email,
        name: finalInput,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.success != true) return;
        setSuccessMessage("Please check your inbox in about 10 Mins");
        setTimeout(() => {
          scrollToNext();
        }, 3000);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <div
        className="relative bg-cover bg-bottom text-white p-5 md:p-10"
        style={{ backgroundImage: `url(${HeroBg})` }}
      >
        <div className="absolute inset-0 bg-black opacity-95"></div>
        <div className="relative z-10">
          <div className="container mx-auto">
            <nav className="w-full gap-5 items-center justify-center flex">
              <img src={Logo} width={150} alt="" />
              <span className="text-xs tracking-widest px-2 rounded-full bg-main/10 mt-1 border-main/50 border text-main">
                HIRING
              </span>
            </nav>
          </div>
          <div className="pt-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen lg:px-8">
            <div className="max-w-2xl md:min-w-[85%] mx-auto w-full">
              <div className="text-center">
                <h1 className="text-white">
                  Grow a sustainable real estate business without {" "}
                  <span className="text-main">
                    referral-stress, following up, cold calling &
                    unpredictability.
                  </span>
                </h1>
                <div className="max-w-2xl md:mx-auto sm:text-center lg:max-w-5xl md:mb-12">
                  <p className="my-5 md:my-10 md:text-4xl text-2xl">
                    There’s a smarter way to grow your business with less
                    hustle, creating more income, freedom, and impact.
                  </p>
                  <form
                    className="flex-col items-center w-full mb-5 md:flex-row md:px-7 hidden"
                    onSubmit={
                      !finalQuestion ? handleEmailSubmit : handleFinalSubmit
                    }
                  >
                    <div className="relative w-full">
                      <img
                        src={Crystal}
                        className="absolute z-20 w-16 rotate-[-15deg] top-[12px] left-2.5 hidden md:flex"
                        alt="Crystal Ball"
                      />
                      {successMessage ? (
                        <div className="flex items-center justify-center w-full h-16 md:h-20 text-xl md:text-2xl text-center bg-white text-[#3d3d3d] rounded-full">
                          <IoCheckmarkCircle className="mr-2 text-green-500" />
                          {successMessage}
                        </div>
                      ) : (
                        <input
                          placeholder={
                            !finalQuestion
                              ? "Enter your email here..."
                              : "Please enter your name too..."
                          }
                          required
                          type="text"
                          value={!finalQuestion ? email : finalInput}
                          onChange={(e) =>
                            !finalQuestion
                              ? setEmail(e.target.value)
                              : setFinalInput(e.target.value)
                          }
                          ref={finalQuestion ? finalInputRef : null} // Set ref when finalQuestion is true
                          className={`flex-grow w-full h-16 md:h-20 py-5 transition text-center md:text-left duration-200 bg-white text-[#3d3d3d] appearance-none md:pl-[83px] text-xl md:text-2xl focus:outline-none focus:shadow-outline rounded-t-[30px] md:rounded-l-full placeholder:text-[#6d6d6d] ${
                            emailError
                              ? "text-red-500 border-2 border-red-500 font-bold"
                              : ""
                          }`}
                        />
                      )}
                    </div>
                    {!successMessage && (
                      <button
                        type="submit"
                        className="inline-flex items-center justify-center h-16 md:h-20 px-6 font-bold tracking-wide text-white transition duration-200 w-full md:w-60 bg-main focus:shadow-outline focus:outline-none rounded-b-[30px] md:rounded-r-full text-2xl"
                        disabled={loading} // Disable button when loading
                      >
                        {loading ? ( // Show loader when loading
                          <span className="loader"></span>
                        ) : !finalQuestion ? (
                          "Claim!"
                        ) : (
                          "Claim Now!"
                        )}
                      </button>
                    )}
                  </form>
                  <p className="text-sm text-center text-white/60 hidden">
                    Receive our{" "}
                    <span className="italic font-bold text-pink-500">
                      100% LEGAL
                    </span>{" "}
                    methods for free... PS: We don't spam.
                  </p>
                  <div className="flex">
                    <Link to="/strategy-session" className="w-full">
                      <button
                        className="funnelCTA"
                        style={{ backgroundColor: "#0086ff" }}
                      >
                        <div className="slant cta">
                          See how in our FREE 20-MIN STRATEGY SESSION
                        </div>
                        <p
                          className="!font-bold text-xs md:text-sm mx-auto w-full slant mt-1"
                          style={{ color: `#00000070` }}
                        >
                          NO OBLIGATIONS. NO BS. NO HARD-SELL.
                        </p>
                      </button>
                    </Link>
                  </div>
                  <div
                    onClick={scrollToNext}
                    className="cursor-pointer hidden px-10 w-fit mx-auto mt-10"
                  >
                    <button className="p-3 rounded-full text-white animate-bounce-slow bg-white/5 text-2xl">
                      <IoChevronDown />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
