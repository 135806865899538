import React from "react";
import Button from "./Button";
import Leaf from "../assets/leaf.svg";
import Stats from "../assets/stats.png";
import Statista from "../assets/statista logo.png";
import Leads from "../assets/leads.png";
import Title from "../assets/title.svg";

const Guarantee = () => {
  return (
    <div className="relative overflow-hidden bg-cover bg-center">
      <div className="py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8 lg:py-20 px-5">
        <div className="max-w-2xl mx-auto sm:max-w-xl md:max-w-5xl">
          <div className="text-center">
            <div className="max-w-2xl my-10 md:mx-auto sm:text-center lg:max-w-5xl md:mb-12">
              <div className="flex items-center justify-center relative mx-auto ">
                <img
                  src={Leaf}
                  className="hidden lg:absolute h-28 -left-3 md:h-[280px] top-0 md:-left-10 md:flex"
                  alt=""
                />
                <img
                  src={Leaf}
                  className="hidden lg:absolute scale-x-[-1] h-28 -right-3 md:h-[280px] top-0 md:-right-10 md:flex"
                  alt=""
                />
                <h2 className="">Predictable, Qualified Leads</h2>
              </div>
                <div>
                  <img src={Leads} className="" alt="" />
                </div>

              <div className="letter">
                <p>
                  Referrals are great—but let’s face it, they aren’t exactly predictable. One month you’re getting referrals, the next? Silence.
                </p>
                <p>
                  <span className="highlight">
                    To grow steadily, you need more than just word-of-mouth. You need a reliable system that brings leads to you, without hoping someone mentions your name.
                  </span>
                </p>
                <p>
                  Think about it: if referrals aren’t keeping up, you’re left hustling harder each month just to maintain your business.
                </p>
                <p>
                  <span className="highlight">
                    It’s time for a strategy that fills in the gaps—a way to reach clients on your terms, consistently.
                  </span>
                </p>
                <p>
                  <span className="italic">Consider this:</span> global digital ad spend is expected to soar to $870 billion by 2027. <span className="font-semibold">(Yes, billion with a “B.”)</span>
                </p>
                <p>
                  The demand is there, and if you’re not putting yourself in front of that demand, you’re missing out on growth.
                </p>
              </div>
              <img
                src={Title}
                className="w-full md:w-[70%] mx-auto mb-5"
                alt=""
              />
              <div>
                <div className="overflow-hidden shadow rounded-xl mx-auto md:w-[80%]">
                  <img src={Stats} className="" alt="" />
                </div>
                <div className="flex items-center text-sm mx-auto md:w-[80%] mt-2 justify-end gap-2">
                  <p className="text-gray-400 ">Source:</p>
                  <a
                    href="https://www.statista.com/statistics/237974/online-advertising-spending-worldwide/#:~:text=It%20was%20calculated%20that%20the,would%20reach%20870.85%20billion%20dollars."
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Statista} width={72} alt="" />
                  </a>
                </div>
              </div>
              <div className="letter">
                <p>
                  That’s where we come in—<span className="font-semibold">making sure you’re part of the conversation</span> clients are already having online.
                </p>
                <p>
                  Imagine having a system that attracts leads while you’re focusing on clients. No more “hope marketing” or relying on a friend of a friend to send you their cousin.
                </p>
                <p>
                  <span className="highlight">
                    With our strategic approach, you’re set up for <span className="font-semibold italic">predictable, qualified</span> leads, month after month.
                  </span>
                </p>
                <p>You deserve to focus on closing deals, not wondering where the next one is coming from.</p>
                <p>Let us show you how.</p>
              </div>
              <div className="z-10 w-full">
                <Button color="#0086FF" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guarantee;
