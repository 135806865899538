import React from "react";
import Guide from "../assets/guide.png";
import Highlight from "../assets/highlight.png";
import Button from "./Button";
import Gift from "../assets/gift.png";

const Magnet = () => {
  return (
    <div
      className="relative text-white bg-main overflow-hidden md:bg-contain bg-bottom"
      style={{ backgroundImage: `url(${Highlight})` }}
    >
      <div className="py-16 px-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-2xl mx-auto sm:max-w-xl md:max-w-5xl">
          <div className="text-center">
            <div className="max-w-2xl md:my-16 md:mx-auto sm:text-center lg:max-w-5xl">
              <div className="bonus">free bonus!</div>
              <h2 className="mb-5 md:mb-0">
                GET THE EXCLUSIVE GUIDE{" "}
                <span className="relative">
                  <div className="absolute md:w-52 md:h-5 shadow-md bg-white md:-left-5 md:top-16 left-1 top-10 w-20 h-3 rotate-[8deg]"></div>
                  $27
                </span>{" "}
                FREE!
              </h2>
              <div className="relative flex flex-col items-center justify-center">
                <img
                  src={Guide}
                  className="w-full md:w-full z-20 -mt-3 rotate-[4deg]"
                  alt="Guide"
                />
                <div className="z-10 w-full">
                  <Button
                    color="#000000"
                    subcolor="FFE125"
                    cta="Claim the limited $27 guide for $0"
                    sub="No obligations. No cc. No hard sell."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Magnet;
