import React, { useState } from "react";
import Button from "./Button";
import Speech from "../assets/speech.png";
import { IoChevronDown } from "react-icons/io5";
import { IoChevronUp } from "react-icons/io5";

const faqData = [
  {
    question: "Is there really a performance guarantee?",
    answer:
      "Absolutely. Our “Outperform or Don’t Pay” guarantee means we’re fully invested in your success. If we don’t outperform your previous best results, you’re not on the hook for payment. This keeps us motivated to bring our A-game and ensure you see a genuine impact.",
  },
  {
    question: "What makes you different from other marketing providers?",
    answer:
      "We don’t just promise results; we guarantee them. While others might focus on vanity metrics, we’re laser-focused on driving qualified buyers and tangible growth for your business. This is about hitting—and surpassing—your highest performance benchmarks.",
  },
  {
    question: "How soon will I start seeing results?",
    answer:
      "Our approach is designed to deliver quick wins, but we’re also focused on building sustained growth. Many clients see a measurable increase in engagement and conversions within the first month, and results compound over time as we optimize further.",
  },
];

const Questions = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleQuestion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="relative overflow-hidden bg-cover bg-center">
      <div className="py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8 lg:py-20 px-5">
        <div className="max-w-2xl mx-auto sm:max-w-xl md:max-w-5xl">
          <div className="text-center">
            <div className="max-w-2xl mb-10 md:mx-auto sm:text-center lg:max-w-5xl md:mb-12">
              <div className="flex items-center justify-center gap-5 w-28 mx-auto mb-5">
                <img src={Speech} alt="Speech bubble icon" />
              </div>
              <h2>YOUR QUESTIONS, ANSWERED!</h2>

              {/* FAQ Section */}
              <div className="faq-container space-y-3 mt-10">
                {faqData.map((faq, index) => (
                  <div
                    key={index}
                    className="shadow-sm rounded-2xl px-5 md:px-10 py-5 bg-white cursor-pointer"
                    onClick={() => toggleQuestion(index)}
                  >
                    <div className="flex justify-between items-center ">
                      <p className="font-semibold text-xl text-left md:text-4xl opacity-90">
                        {faq.question}
                      </p>
                      <span className="!text-3xl">
                        {activeIndex === index ? (
                          <IoChevronDown />
                        ) : (
                          <IoChevronUp />
                        )}
                      </span>
                    </div>
                    {activeIndex === index && (
                      <div className="mt-5 text-xl md:text-2xl text-left opacity-70">
                        <p>{faq.answer}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {/* CTA Button */}
              <div className="z-10 w-full mt-10">
                <Button />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questions;
