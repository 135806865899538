import React from "react";
import Button from "./Button";
import Bracket from "../assets/bracket.svg";
import Meeting from "../assets/meeting.svg";

const Letter = () => {
  return (
    <div
      className="relative overflow-hidden bg-cover bg-center"
      id="next-section"
    >
      <div className="p-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8 lg:py-8">
        <div className="max-w-2xl mx-auto sm:max-w-xl md:max-w-5xl">
          <div className="text-center">
            <div className="max-w-2xl mb-10 md:mx-auto sm:text-center lg:max-w-5xl md:mb-12">
              <div className="letter">
                <p>Let’s be real for a sec...</p>
                <p>
                  You’re a busy real estate agent, juggling showings, client
                  calls, and negotiations, doing everything you can to keep
                  things moving.
                </p>
                <p>
                  But there’s always that one nagging thing in the background,
                  adding a layer of stress you don’t need.
                </p>
                <p>Yep.</p>
                <p>Relying on <span className="font-semibold">referrals.</span></p>
                <p>
                  You’ve built a network, and people trust you, but it’s unpredictable.
                  One month, you’re booked solid; the next, leads feel like they’re drying up.
                </p>
                <p>
                  <span className="highlight">
                    So you find yourself *hoping* your clients come through—again and again.
                    But deep down, you know it’s a cycle that keeps you on edge.
                  </span>
                </p>
                <p>
                  The truth is, every time you rely on referrals alone, you’re giving up control over your lead flow.
                </p>
                <p>
                  After a long day, you start thinking you “should” be doing more...
                </p>
                <p>
                  But the stress builds as you realize that, no matter how much you rely on others to send you clients, you’re not building anything *sustainable*.
                </p>
                <p><span className="font-semibold">Here’s the reality:</span> referrals eventually dry up.</p>
                <p>
                  People have their own lives. They get busy, or they assume you’re already booked. Suddenly, you’re left wondering when the next client will show up.
                </p>
                <p>
                  <span className="highlight">
                    Sound familiar?
                  </span>
                </p>
                <p>
                  It’s frustrating, right?
                </p>
                <p>
                  It’s not about hustling harder; it’s about building a business that doesn’t keep you stuck in survival mode.
                </p>
                <div className="z-10 w-full pb-24">
                  <Button />
                </div>
                <div className="relative pt-20">
                  <img
                    src={Bracket}
                    className="absolute w-52 -top-10 md:-top-5 right-10 md:-right-16"
                    alt=""
                  />
                  <h2>They have been lying to us!</h2>
                </div>
                <div>
                  <img src={Meeting} className="" alt="" />
                </div>
                <p>
                  Here’s the thing no one tells you about referrals and lead flow.
                </p>
                <p>
                  When you depend on referrals alone, it feels like an endless cycle of *hoping* for leads instead of creating predictable growth.
                </p>
                <p>
                  And yet, <span className="font-semibold highlight">having predictable lead flow is non-negotiable if you want a scalable business.</span>
                </p>
                <p>
                  Without a steady stream of potential clients coming in, you’re missing out on growth opportunities.
                </p>
                <p>
                  <span className="highlight">
                    People are out there <span className="font-semibold">right now</span>, looking for agents like you. But if you’re not visible or predictable, you’re missing your chance.
                  </span>
                </p>
                <p>
                  The problem?
                </p>
                <p>
                  You can’t keep your business growing with just referrals.
                </p>
                <p>
                  <span className="highlight">
                    You shouldn’t have to stress about where your next client is coming from.
                  </span>
                </p>
                <p>The good news?</p>
                <p>You don’t have to...</p>
              </div>
              <div className="z-10 w-full">
                <Button />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Letter;
