import Confetti from "../assets/confetti-ball_1f38a.png";
import Rocket from "../assets/rocket_1f680.png";

const Start = ({ clientname }) => {
  return (
    <>
      <div className="funnel !overflow-visible mt-20">
        <div className="flex items-center justify-center gap-2">
          <img src={Confetti} className="w-16 md:w-28" alt="" />
          <img src={Rocket} className="w-16 md:w-28" alt="" />
        </div>
        <h2 className="md:mx-auto md:w-6/12 !text-5xl md:!text-8xl gap-3 my-5">
          Welcome aboard, {clientname}!{" "}
        </h2>
        <p className="text-2xl md:text-3xl font-medium md:w-3/6 md:mx-auto mb-10">
          Take this quick onboarding to get custom strategies that will
          help you generate leads and close deals faster.{" "}
          <span className="font-bold highlight">
            The more details you provide, the more accurate your results will
            be.
          </span>{" "}
          Let’s get started! 👇
        </p>
      </div>
    </>
  );
};

export default Start;
