import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight, FaCheck } from "react-icons/fa";
import { useParams } from "react-router-dom"; // Import useParams to extract the name from the URL
import Logo from "../assets/primary.svg";
import Envelope from "../assets/envelope-with-arrow_1f4e9 1.png";
import Gear from "../assets/gear_2699-fe0f 1.png";
import Start from "../onboarding/Start";

const phases = [
  {
    name: "Phase 1: Let’s Get to Know Your Brand!",
    questions: [
      {
        id: 1,
        text: "What’s the name of your agency or team?",
        skippable: false,
      },
      {
        id: 2,
        text: "Who’s our main go-to person for all things marketing magic? Tell us your name here!",
        skippable: false,
      },
      {
        id: 5,
        text: "How do you prefer we keep in touch – are you an email kind of person, WhatsApp fan, or do you prefer good old phone calls?",
        skippable: true,
      },
    ],
  },
  {
    name: "Phase 2: Bring on the Visuals – We’re Ready!",
    questions: [
      {
        id: 6,
        text: "Upload your logo – we’d love to see it in all its high-res glory!",
        skippable: true,
      },
      {
        id: 7,
        text: "Pick your primary, secondary, and accent brand colors – it’s all about making your brand pop!",
        skippable: true,
      },
      {
        id: 9,
        text: "If you’ve got a brand guide, go ahead and share it with us – we’re here to keep everything consistent!",
        skippable: true,
      },
      {
        id: 10,
        text: "Upload headshots of your team or just yourself if it’s a solo operation – we love to put names to faces!",
        skippable: true,
      },
      {
        id: 11,
        text: "Got a great shot of your office? Feel free to upload it too – gives a nice personal touch to everything!",
        skippable: true,
      },
      {
        id: 13,
        text: "Drop a link to your current listings or upload a file – we want to make your properties shine!",
        skippable: true,
      },
      {
        id: 14,
        text: "Any testimonials or client success stories you’re proud of? Upload them here so we can help your future clients see the magic!",
        skippable: true,
      },
      {
        id: 15,
        text: "Got some killer property images or videos? Share them here – these visuals will help us create scroll-stopping ads!",
        skippable: true,
      },
    ],
  },
  {
    name: "Phase 3: Let’s Define Your Campaign Goals and Kick Things Off!",
    questions: [
      {
        id: 18,
        text: "Who are we targeting? Give us a little background on your ideal audience: location, age, type of property, and anything else that makes them the right fit!",
        skippable: true,
      },
      {
        id: 19,
        text: "How hands-on do you want to be with ads? Want to review everything before it goes live, or are you happy to let us take the reins?",
        skippable: true,
      },
      {
        id: 20,
        text: "Got any privacy policies or compliance docs we should be aware of? Upload them here – we’ll make sure everything stays above board!",
        skippable: true,
      },
      {
        id: 21,
        text: "Please confirm that you’ve read and agree to our terms of service.",
        skippable: false,
      },
      {
        id: 22,
        text: "Do we have your permission to use this data in ads and marketing efforts? Check the box if you’re good to go!",
        skippable: false,
      },
    ],
  },
];

const OnboardingForm = () => {
  const { clientname } = useParams(); // Get client name from URL
  const [currentPhase, setCurrentPhase] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [formData, setFormData] = useState({ answers: {} });
  const [skippedQuestions, setSkippedQuestions] = useState({});
  const [isStarted, setIsStarted] = useState(false); // To track if the user started the form

  const handleChange = (e, questionId) => {
    const updatedAnswers = {
      ...formData.answers,
      [questionId]: e.target.value,
    };
    setFormData({ ...formData, answers: updatedAnswers });
  };

  const handleSkip = (questionId) => {
    const updatedSkipped = { ...skippedQuestions, [questionId]: true };
    setSkippedQuestions(updatedSkipped);
    const updatedAnswers = { ...formData.answers };
    delete updatedAnswers[questionId];
    setFormData({ ...formData, answers: updatedAnswers });
    handleNextQuestion();
  };

  const handleNextQuestion = () => {
    const phase = phases[currentPhase];
    if (currentQuestionIndex < phase.questions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
    } else {
      handleNextPhase();
    }
  };

  const handlePrevQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prev) => prev - 1);
    } else {
      handlePrevPhase();
    }
  };

  const handleNextPhase = () => {
    if (currentPhase < phases.length - 1) {
      setCurrentPhase((prev) => prev + 1);
      setCurrentQuestionIndex(0);
    }
  };

  const handlePrevPhase = () => {
    if (currentPhase > 0) {
      setCurrentPhase((prev) => prev - 1);
      setCurrentQuestionIndex(phases[currentPhase - 1].questions.length - 1);
    }
  };

  const renderCurrentQuestion = () => {
    const phase = phases[currentPhase];
    const question = phase.questions[currentQuestionIndex];
    return (
      <div
        key={question.id}
        className="mt-20 text-center form-question mx-5 flex flex-col"
      >
        <label className="lg:w-6/12 mx-auto">{question.text}</label>
        <input
          className="form-input"
          type="text"
          value={formData.answers[question.id] || ""}
          onChange={(e) => handleChange(e, question.id)}
          placeholder={`Answer to question ${question.id}`}
        />
        {question.skippable && !skippedQuestions[question.id] && (
          <button
            className="mt-2 text-gray-400 font-medium text-base"
            onClick={() => handleSkip(question.id)}
          >
            Skip this question
          </button>
        )}
      </div>
    );
  };

  return (
    <section className="mb-20">
      <nav className="w-full h-16 shadow-sm !bg-white flex items-center justify-center gap-5">
        <img src={Logo} width={140} alt="Company Logo" />
      </nav>

      {/* Phase Navigation */}
      <div className="md:w-5/12 lg:w-4/12 mt-20 mx-5 md:mx-auto relative flex items-center justify-center">
        <img
          src={Gear}
          className="absolute z-20 w-12 -right-7 top-5 hidden md:block"
          alt=""
        />
        <img
          src={Envelope}
          className="absolute z-20 w-14 -left-7 bottom-6 hidden md:block"
          alt=""
        />
        <div className="flex items-center justify-between w-full relative bg-white rounded-xl shadow p-4 px-7">
          <div className="flex items-center">
            <div
              className={`w-7 h-7 rounded-full flex items-center justify-center border-[3px] mr-2 ${
                currentPhase === 0
                  ? "border-main text-main"
                  : currentPhase > 0
                  ? "bg-main text-white border-main"
                  : "border-gray-300 text-gray-300"
              }`}
            >
              {currentPhase > 0 && <FaCheck className="text-white text-sm" />}
            </div>
            <p
              className={`font-medium text-lg ${
                currentPhase >= 0 ? "font-semibold" : "text-gray-300"
              }`}
            >
              Information
            </p>
          </div>
          <FaChevronRight className={`text-gray-300`} />
          <div className="flex items-center">
            <div
              className={`w-7 h-7 rounded-full flex items-center justify-center border-[3px] mr-2 ${
                currentPhase === 1
                  ? "border-main text-main"
                  : currentPhase > 1
                  ? "bg-main text-white border-main"
                  : "border-gray-300 text-gray-300"
              }`}
            >
              {currentPhase > 1 && <FaCheck className="text-white text-sm" />}
            </div>
            <p
              className={`font-medium text-lg ${
                currentPhase >= 1 ? "font-semibold" : "text-gray-300"
              }`}
            >
              Branding
            </p>
          </div>
          <FaChevronRight className={`text-gray-300`} />
          <div className="flex items-center">
            <div
              className={`w-7 h-7 rounded-full flex items-center justify-center border-[3px] mr-2 ${
                currentPhase === 2
                  ? "border-main text-main"
                  : currentPhase > 2
                  ? "bg-main text-white border-main"
                  : "border-gray-300 text-gray-300"
              }`}
            >
              {currentPhase > 2 && <FaCheck className="text-white text-sm" />}
            </div>
            <p
              className={`font-medium text-lg ${
                currentPhase >= 2 ? "font-semibold" : "text-gray-300"
              }`}
            >
              Access
            </p>
          </div>
        </div>
      </div>

      {/* Start Phase */}
      {!isStarted ? (
        <Start clientname={clientname} />
      ) : (
        <div className="mt-20 text-center form-question leading-none">
          {renderCurrentQuestion()}
        </div>
      )}

      {/* Navigation Buttons */}
      <div className="flex md:w-3/12 mx-5 md:mx-auto flex-col gap-5 items-center justify-center mt-5">
        <div className="flex w-full gap-2">
          {/* Conditionally Render Back Button */}
          {!(currentPhase === 0 && currentQuestionIndex === 0) && (
            <button
              className="w-16 flex items-center justify-center text-2xl font-semibold bg-gray-300 text-gray-500 rounded-xl"
              onClick={handlePrevQuestion}
            >
              <FaChevronLeft />
            </button>
          )}
          <button
            className={`${
              currentPhase === 0 && currentQuestionIndex === 0
                ? "w-full"
                : "w-[calc(100%-4.5rem)]"
            } text-2xl font-semibold p-5 bg-main text-white rounded-xl`}
            onClick={() => {
              if (!isStarted) {
                setIsStarted(true);
              } else {
                // Check if it's the last question (based on currentPhase and currentQuestionIndex)
                if (
                  currentPhase === phases.length - 1 &&
                  currentQuestionIndex ===
                    phases[currentPhase].questions.length - 1
                ) {
                  // Submit logic here
                  console.log("Form Submitted!");
                } else {
                  handleNextQuestion();
                }
              }
            }}
          >
            {
              isStarted
                ? currentPhase === phases.length - 1 &&
                  currentQuestionIndex ===
                    phases[currentPhase].questions.length - 1
                  ? "Submit" // Show "Submit" on the last question
                  : "Next" // Show "Next" for other questions
                : "Start" // Show "Start" if not started yet
            }
          </button>
        </div>
      </div>
    </section>
  );
};

export default OnboardingForm;
