import Telephone from "../assets/phone.png";
import Chart from "../assets/chart-increasing_1f4c8.png";

const Intro = () => {
  return (
    <>
      <div className="funnel !overflow-visible">
        <div className="flex items-center justify-center gap-2">
          <img src={Telephone} className="w-16 md:w-28" alt="" />
          <img src={Chart} className="w-16 md:w-28" alt="" />
        </div>
        <h2 className="md:mx-auto md:w-6/12 !text-5xl md:!text-8xl gap-3 my-5">
          Free 20-Minute growth strategy session call{" "}
        </h2>
        <p className="text-2xl md:text-3xl font-medium md:w-3/6 md:mx-auto">
          Claim your FREE{" "}
          <span className="font-bold">NO-OBLIGATION, NO BS</span> growth call{" "}
          <span className="text-green-600 font-bold">worth $412</span>. Strictly
          for serious people genuinely looking to drastically scale their real
          estate businesses and escape referral stress.
        </p>
      </div>
    </>
  );
};

export default Intro;
