import React, { useEffect, useRef } from "react";
const Slider = () => {
  const leftRowRef = useRef(null);
  const rightRowRef = useRef(null);

  const slides = [
    "BUYERS",
    "SELLERS",
    "REFERRALS",
    "LISTINGS",
    "QUALIFIED LEADS",
    "& SO MUCH MORE",
    "BUYERS",
    "SELLERS",
    "REFERRALS",
    "LISTINGS",
    "QUALIFIED LEADS",
    "& SO MUCH MORE",
    "BUYERS",
    "SELLERS",
    "REFERRALS",
    "LISTINGS",
    "QUALIFIED LEADS",
    "& SO MUCH MORE",
    "BUYERS",
    "SELLERS",
    "REFERRALS",
    "LISTINGS",
    "QUALIFIED LEADS",
    "& SO MUCH MORE",
    "BUYERS",
    "SELLERS",
    "REFERRALS",
    "LISTINGS",
    "QUALIFIED LEADS",
    "& SO MUCH MORE",
    "BUYERS",
    "SELLERS",
    "REFERRALS",
    "LISTINGS",
    "QUALIFIED LEADS",
    "& SO MUCH MORE",
    "BUYERS",
    "SELLERS",
    "REFERRALS",
    "LISTINGS",
    "QUALIFIED LEADS",
    "& SO MUCH MORE",
    "BUYERS",
    "SELLERS",
    "REFERRALS",
    "LISTINGS",
    "QUALIFIED LEADS",
    "& SO MUCH MORE",
    "BUYERS",
    "SELLERS",
    "REFERRALS",
    "LISTINGS",
    "QUALIFIED LEADS",
    "& SO MUCH MORE",
    "BUYERS",
    "SELLERS",
    "REFERRALS",
    "LISTINGS",
    "QUALIFIED LEADS",
    "& SO MUCH MORE",
    "BUYERS",
    "SELLERS",
    "REFERRALS",
    "LISTINGS",
    "QUALIFIED LEADS",
    "& SO MUCH MORE",
  ];

  // Function to initialize the infinite scroll
  const startScrolling = (rowRef, direction) => {
    const row = rowRef.current;
    const speed = direction === "left" ? 50 : -50; // Different speeds for left/right
    let scrollAmount = 0;

    const scroll = () => {
      scrollAmount += speed * 0.01; // Adjust scroll speed
      row.style.transform = `translateX(${scrollAmount}px)`;

      // Loop back if content is scrolled completely
      if (Math.abs(scrollAmount) >= row.scrollWidth / 2) {
        scrollAmount = 1;
      }

      requestAnimationFrame(scroll);
    };

    scroll();
  };

  useEffect(() => {
    startScrolling(rightRowRef, "right");
    startScrolling(leftRowRef, "left");
    startScrolling(rightRowRef, "right");
  }, []);

  return (
    <div className="sliding-container !overflow-hidden">
      <div className="sliding-wrapper">
        <div className="sliding-row right-slide" ref={rightRowRef}>
          {slides.concat(slides).map((slide, index) => (
            <div key={index} className="text-slide">
              {slide}
            </div>
          ))}
        </div>
      </div>

      <div className="sliding-wrapper">
        <div className="sliding-row right-slide" ref={leftRowRef}>
          {slides.concat(slides).map((slide, index) => (
            <div key={index} className="text-slide">
              {slide}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Slider;
