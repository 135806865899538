import React, { useEffect } from "react";
import Button from "./Button";
import Gif from "../assets/giphy.webp";
import { IoCloseSharp } from "react-icons/io5";

const Wait = ({ isVisible, onClose }) => {
  useEffect(() => {
    // Add or remove class based on modal visibility
    if (isVisible) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    // Cleanup function to remove the class when the modal is closed
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-80 backdrop-blur flex justify-center items-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-white px-10 pb-4 pt-10 rounded-[40px] shadow-lg max-w-2xl w-full text-center"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className="text-3xl absolute right-10 top-10 text-white p-5 cursor-pointer"
          onClick={onClose}
        >
          <IoCloseSharp />
        </div>
        <h2 className="!text-8xl slant">32 SECONDS...</h2>
        <p className="text-2xl my-5">
          That’s all it takes to complete the form &{" "}
          <span className="font-bold">
            claim the secret guide{" "}
            <span className="relative">
              $27{" "}
              <div className="w-12 rotate-6 top-3 h-1 bg-black absolute left-0"></div>
            </span>{" "}
            FREE.
          </span>{" "}
          What’s 32 seconds when it could change everything?
        </p>
        <div className="relative">
          <p className="text-3xl text-lime-400 uppercase absolute font-black bottom-10 left-52 -rotate-12">*focus*</p>
          <img
            src={Gif}
            alt="Special Offer"
            className="mx-auto mb-4 rounded-xl w-full"
          />
        </div>
        <div onClick={onClose}>
          <Button cta="CLAIM $27 GUIDE FOR FREE!" color="#0086FF" />
        </div>
      </div>
    </div>
  );
};

export default Wait;
