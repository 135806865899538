import React from "react";
import Button from "./Button";
import Stop from "../assets/stop.png";
import Point from "../assets/point.png";

const Requirements = () => {
  return (
    <div className="relative overflow-hidden bg-cover bg-center">
      <div className="py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8 lg:py-20 p-5">
        <div className="max-w-2xl mx-auto sm:max-w-xl md:max-w-5xl">
          <div className="text-center">
            <div className="max-w-2xl mb-10 md:mx-auto sm:text-center lg:max-w-5xl md:mb-12">
              <div className="flex items-center justify-center gap-5 w-28 mx-auto mb-5">
                <img src={Stop} alt="" />
                <img src={Point} alt="" />
              </div>
              <div className="bg-main shadow py-1 my-5 rounded md:rounded-full w-fit px-3 mx-auto">
                <p className="text-sm md:text-sm leading-4 font-bold text-white">LIMITED OFFER: WE RESERVE THE RIGHT TO REVOKE THIS AT ANY TIME.</p>
              </div>
              <h2>
              Get up to 10+ Leads Before You Decide. No cost, no risk.
              </h2>
              <div className="letter">
                <p>
                We’re offering this results-first approach to a select few real estate agents who meet our criteria. Get your first 10+ leads on us, and only then decide if you want to continue—or walk away, no strings attached.
                </p>
                <div className="flex mb-10 items-center">
                  <div className="mr-7 md:mr-20">
                    <h2 className="text-main inter font-bold">*</h2>
                  </div>
                  <div>
                    <p>
                    This isn’t for everyone. We carefully choose partners who are serious about scaling fast and fit our criteria for success. If that’s you, bring your A-game, and we’ll bring ours.
                    </p>
                  </div>
                </div>
                <div className="flex mb-10 items-center">
                  <div className="mr-7 md:mr-20">
                    <h2 className="text-main inter font-bold">*</h2>
                  </div>
                  <div>
                    <p>
                    Our strategy doesn’t just attract leads; it builds momentum. If you’re ready to step up, let’s see if we’re a match—<span className="font-bold highlight">no cost until we deliver leads</span>.
                    </p>
                  </div>
                </div>
                <div className="flex mb-10 items-center">
                  <div className="mr-7 md:mr-20">
                    <h2 className="text-main inter font-bold">*</h2>
                  </div>
                  <div>
                    <p>
                      This isn’t for everyone.{" "}
                      <span className="font-bold">
                        We’re here for those willing to take bold steps
                      </span>{" "}
                      towards sustainable growth—not just quick wins. If that’s
                      you, let’s make it happen.
                    </p>
                  </div>
                </div>
              </div>
              <div className="z-10 w-full">
                <Button />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Requirements;
